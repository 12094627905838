import * as Yup from "yup";
const emailNotLongEnough = "Email must be at least 3 characters";
const nameNotLongEnough = "Name must be at least 3 characters";
const passwordNotLongEnough = "Password must be at least 8 characters";
const invalidEmail = "Email must have a valid format";
const invalidPostcodeFormat = "Invalid format";
const postcodeLength = "Invalid postcode format";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .min(4, "Email must be greater then 3 character")
    .email("Invalid email format")

    // .matches(
    //   /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/, //NOSONAR
    //   "email format is invalid"
    // ),
    .matches(
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i, //NOSONAR
      "email format is invalid"
    ),
    
    
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase and one number"
    ),
});

export const validationSchemaSignup = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/)
    .min(3, nameNotLongEnough)
    .max(40)
    .required(),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/)
    .max(40)
    .required(),
  email: Yup.string()
    .required("Email is a required field")
    .min(4, emailNotLongEnough)
    .email(invalidEmail)

    .matches(
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/, //NOSONAR
      "email format is invalid"
    ),
  password1: Yup.string()
    .required("Password is a required field")
    .min(8, passwordNotLongEnough)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase and one number"
    ),

  password2: Yup.string().oneOf(
    [Yup.ref("password1"), null],
    "Passwords must match"
  ),
});
export const postcodeValidationSchema = Yup.object().shape({
  postcode: Yup.string()
    // .required("required field")

    .max(8, postcodeLength),
});
export const validationSchemaSetPassword = Yup.object().shape({
  new_password: Yup.string()
    .required("Password is a required field")
    .min(8, passwordNotLongEnough)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase and one number"
    ),

  confirm_password: Yup.string().oneOf(
    [Yup.ref("new_password"), null],
    "Passwords must match"
  ),
});
