import React, { useEffect, useRef } from 'react';
import Marzipano from 'marzipano';

const PanoramaComponent = () => {
  const viewerRef = useRef(null);

  useEffect(() => {
    const viewerElement = viewerRef.current;
    const viewer = new Marzipano.Viewer(viewerElement);

    const faceMap = {
      b: 'b',  
      f: 'f', 
      l: 'l', 
      r: 'r',  
      u: 'u',  
      d: 'd',  
    };

    const source = Marzipano.ImageUrlSource.fromString(
      'https://ctadmin.civiltracker.xyz/api/pano_static_view_generate/{z}/{f}{y}_{x}.jpg',  
      {
        // cubeMapPreviewUrl: 'output/preview.jpg',  
        faceMap: faceMap, 
      }
    );

    const geometry = new Marzipano.CubeGeometry([
      { tileSize: 512, size: 512 },     
      { tileSize: 1024, size: 1024 },   
      // { tileSize: 2048, size: 2048 },   
      // { tileSize: 4096, size: 4096 },   
    ]);

    const limiter = Marzipano.RectilinearView.limit.traditional(8192, 100 * Math.PI / 180);
    // const limiter = Marzipano.RectilinearView.limit.traditional(16384, 100 * Math.PI / 180);
    // const view = new Marzipano.RectilinearView({ yaw: 0, pitch: 0, fov: Math.PI / 2 }, limiter);
    const view = new Marzipano.RectilinearView(null, limiter);

    const scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      webglConstraints: {
        alpha: false,
        depth: false,
        antialias: false,
        preserveDrawingBuffer: false,
        powerPreference: 'high-performance'
      }
      // pinFirstLevel: true
    });

    scene.switchTo();  

    return () => {
      viewer.destroy(); 
    };
  }, []);

  return <div ref={viewerRef} style={{ width: '100%', height: '100vh' }} />;
};

export default PanoramaComponent;
